@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

body {
  font-size: 14px;
  font-weight: 400;
  background-color: #ffffff;
  margin: 0;
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a {
  color: #0884CC;
  text-decoration: none;
}
a:hover {
  color: #1EACFF;
}
#nav-panel {
  min-width: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: #f5f2eb;
}
.panel {
  max-height: 100vh;
  flex: 1 1 auto;
  background-color: white;
}
.notebook-container {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  align-items: center;
}
/* .notebook-container > .notebook {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  height: calc(100vh - 40px);
  overflow-y: scroll;
  overflow-x: hidden;
} */
.notebook-and-settings {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: calc(100% - 40px);
}
.notebook-and-settings > .notebook {
  flex: 1 1 auto;
  box-sizing: border-box;
  height: 100%;
}
.notebook.mode-outline,
.notebook.mode-dev {
  padding: 0 20px 80px 20px;
}
.notebook.mode-outline > .note,
.notebook.mode-dev > .note {
  max-width: 900px;
}
.notebook.mode-read {
  background-color: #eeeeee;
}

.embedded > .panel > .notebook-container {
  height: auto;
}
.embedded > .panel > .notebook-container > .notebook {
  height: auto;
  overflow: visible;
  margin-bottom: 60px;
}

#account > a {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px 20px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
}
#account > a:hover {
  color: #da0a0a;
}
.user-icon + .user-name {
  padding-left: 5px;
}
a > .user-icon {
  text-decoration: unset;
  color: #555555;
}
a > .user-name {
  text-decoration: unset;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type='text'],
textarea {
  padding: 5px 6px;
  /* width: calc(100% - 120px); */
  font-size: 14px;
  line-height: 16px;
}

button {
  border-radius: 5px;
  padding: 2px 10px;
  font-family: inherit;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #efcda6;
}

ul {
  list-style-type: none;
  margin: 20px 0 0 0;
  padding: 0;
}

li {
  padding: 8px 0;
}
.note {
  padding: 0;
}

.note-children {
  padding-left: 20px;
}

.note-self {
  display: flex;
  flex-flow: row nowrap;
}
.note-features {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  width: 100%;
}
.note-props {
  display: flex;
  flex-flow: column;
}
.note-actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.note-actions > div.spacer {
  width: 20px;
  height: 100%;
  cursor: default;
}
/* depth lines */
.note-children {
  position: relative;
}
.note.-view > .note-children:before {
  content: '';
  display: block;
  height: 100%;
  width: 1px;
  border-left: 1px solid #f3f3f3;
  position: absolute;
  left: 10px;
  top: 0;
}
/* end of depth lines */
.prop-label {
  border-radius: 15px;
  padding: 0px 10px 0 0;
  color: #bbb;
  flex: 0 0 25%;
  text-align: right;
  align-self: center;
}
.prop-value {
  display: flex;
  flex-flow: column;
  flex: 1 1;
}
.note-value {
  padding: 5px 6px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.note-value .name {
  margin-right: 5px;
  color: #888888;
}
.note-value .value {
  /* font-size: 14px; */
  line-height: 140%;
  outline: none;
}
.note-value .value:active,
.note-value .value:focus {
  outline: none;
}

.note-source textarea {
  background: #fbf6cf;
  border: none;
  width: 90%;
}
.note-transformations .prop-value > div {
  position: relative;
}
/* .note-transformations .prop-value input[type='text'],
.note-transformations .prop-value textarea {
  background: #cfebfb;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  width: 90%;
} */

.note-transformations .prop-value textarea {
  padding-left: 20px;
}
.transformation-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: white;
  height: 27px;
  flex-flow: row;
}
.notebook .note .note-references {
  display: none;
}

/* Mac-style scrollbars for Windows' Chrome, Safari, Edge */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 100px;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid transparent;
  min-height: 10px;
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical {
  min-height: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  min-width: 10px;
}

.custom-suggestions {
  cursor: pointer;
  list-style: none;
  margin: 0;
  padding: 0;
}
.custom-suggestions--active {
  background-color: #19b5fe;
}
.custom-suggestions li {
  text-align: left;
  color: white;
}
.custom-suggestions li:hover {
  background-color: #19b5fe;
}

.input__marker {
  background-color: #111;
  border-radius: 4px;
  box-shadow: 0 2px 5px #585858;
  display: block;
  font-size: 12px;
  padding: 4px 6px;
  position: absolute;
  -webkit-transform: translate(5%, -70%);
  transform: translate(5%, -70%);
  -webkit-transition: top 0.1s ease 0s, left 0.1s ease 0s;
  transition: top 0.1s ease 0s, left 0.1s ease 0s;
  white-space: nowrap;
  width: auto;
  z-index: 9999;
}
.input__marker:after {
  background-color: #111;
  content: '';
  height: 10px;
  position: absolute;
  width: 15px;
  z-index: -1;
}
.input__marker--position:after {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-10%, 10%) rotate(-15deg) skewX(-40deg);
  transform: translate(-10%, 10%) rotate(-15deg) skewX(-40deg);
}
.input__marker--selection {
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
.input__marker--selection:after {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0) rotate(45deg);
  transform: translate(-50%, 0) rotate(45deg);
}
.input__marker--custom {
  -webkit-transform: translate(16px, 16px);
  transform: translate(16px, 16px);
}
.input__marker--custom:after {
  display: none;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
